import React, { ReactElement } from "react";
import {
  Breadcrumbs,
  BannerHeader,
  InThisSection,
  InThisSectionLink,
  InThisSectionButton,
} from "@ymcansw-camping/common";
import { graphql, useStaticQuery } from "gatsby";
import Layout, { LayoutProps } from "./layout";

type SchoolsLayoutProps = LayoutProps & {
  readonly isIndex?: boolean;
};

export default function SchoolsLayout({
  meta,
  isIndex,
  children,
}: SchoolsLayoutProps): ReactElement {
  const { bannerHeaderImage } = useStaticQuery(graphql`
    query SchoolsLayoutQuery {
      bannerHeaderImage: file(relativePath: { eq: "img/schools-bg.jpg" }) {
        ...GeneralPageLayout_bannerHeaderImage
      }
    }
  `);

  const breadcrumbs = isIndex
    ? [meta.title]
    : [{ to: "/schools/", label: "Schools" }, meta.title];
  return (
    <Layout meta={meta}>
      <BannerHeader title="Schools" image={bannerHeaderImage} />
      <div className="page-content md-pl3 lg-pl6 schools-page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9 col-md-push-3">
              <div className="page-content-header">
                <div className="row">
                  <div className="col-xs-12 col-md-7 col-lg-8">
                    <Breadcrumbs items={breadcrumbs} />
                  </div>
                </div>
              </div>
              <div className="content">{children}</div>
            </div>
            <div className="col-xs-12 col-md-3 col-md-pull-9">
              <InThisSection>
                <InThisSectionLink to="/schools/learning-framework/">
                  Values
                </InThisSectionLink>
                <InThisSectionLink to="/schools/residential/">
                  Residential based programs
                </InThisSectionLink>
                <InThisSectionButton to="/contact/">
                  Booking enquiry
                </InThisSectionButton>
              </InThisSection>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
