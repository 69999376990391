import React from "react";
import SchoolsLayout from "../../components/schools-layout";

// type ResidentialData = {
//   readonly pageFile: GatsbyMarkdownFile<{
//     readonly activitiesTableTitle: string
//     readonly activities: SchoolActivitiesTableProps["activities"]
//   }>
// }

export default function ResidentialPage() {
  // const {
  //   pageFile: {
  //     childMarkdownRemark: { frontmatter },
  //   },
  // } = useStaticQuery<ResidentialData>(graphql`
  //   {
  //     pageFile: file(relativePath: { eq: "pages/school-residential.md" }) {
  //       childMarkdownRemark {
  //         frontmatter {
  //           activitiesTableTitle
  //           activities {
  //             title
  //             learningOutcomes
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <SchoolsLayout
      meta={{
        title: "Residential Based Programs",
        description:
          "Each day the students participate in programmed activities designed to challenge participants at both a personal and group level. The activities include adventure learning activities, problem solving initiatives and connection to the natural world focused education.",
      }}
    >
      <div className="content-block">
        <h2>Residential based programs</h2>
        <p>
          The students are based at Camp Yarramundi which has bunk style
          accommodation, a communal dining hall and facilities. Each day the
          students participate in programmed activities designed to challenge
          participants at both a personal and group level. The activities
          include adventure learning activities, problem solving initiatives and
          connection to the natural world focused education. (NB multiple
          activities can be run concurrently in any order to accommodate
          multiple groups)
        </p>
      </div>
      <div className="content-block">
        <h3 className="h5">
          Residential based programs including sample program
        </h3>
        <p className="h6 mt2">Suitable for:</p>
        <ul>
          <li>Primary School – Year 3 to Year 6</li>
          <li>Secondary/High School – Year 7 to Year 12</li>
          <li>Universities</li>
        </ul>
      </div>
      {/* <SchoolActivitiesTable */}
      {/*  title={frontmatter.activitiesTableTitle} */}
      {/*  activities={frontmatter.activities} */}
      {/* /> */}
    </SchoolsLayout>
  );
}
